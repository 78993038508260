import { NgModule, LOCALE_ID } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-root-routing.module';
import { AppRootView } from './app-root.view';
import {
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { LocalStorageService } from './services/storage.service';
import { AppRootServicesModule } from './services/app-root-services.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

@NgModule({
  declarations: [AppRootView],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppRootServicesModule,
  ],
  providers: [
    LocalStorageService,
    provideClientHydration(),
    provideHttpClient(withFetch()),
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [AppRootView],
})
export class AppModule {}
